import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PageWrapper from '../components/PageWrapper'
import SEO from '../components/SEO/SEO'

export const NewsPageTemplate = ({
	content,
	contentComponent,
	title,
	image,
}) => {
	const PostContent = contentComponent || Content
	return (
		<PageWrapper title={title}>
			{image && (
				<div className="news-img-container">
					<img className="news-img" src={image} alt={title} />
				</div>
			)}
			<PostContent className="content" content={content} />
		</PageWrapper>
	)
}

NewsPageTemplate.propTypes = {
	content: PropTypes.node.isRequired,
	contentComponent: PropTypes.func,
	excerpt: PropTypes.string,
	title: PropTypes.string,
	helmet: PropTypes.object,
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const NewsPage = ({ data }) => {
	const { markdownRemark: post } = data

	return (
		<Layout color="black">
			<SEO
				article
				date={post.frontmatter.date}
				title={post.frontmatter.title}
				description={post.excerpt}
				image={post.frontmatter.featuredimage.childImageSharp.fluid.src}
			/>
			<NewsPageTemplate
				content={post.html}
				contentComponent={HTMLContent}
				title={post.frontmatter.title}
				image={post.frontmatter.featuredimage.childImageSharp.fluid.src}
			/>
		</Layout>
	)
}

NewsPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
}

export default NewsPage

export const pageQuery = graphql`
	query NewsPostByID($id: String!) {
		markdownRemark(id: { eq: $id }) {
			excerpt(pruneLength: 150)
			id
			html
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				title
				featuredimage {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 85) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		}
	}
`
